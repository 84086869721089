import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Marquee from "react-fast-marquee";
import {NavLink} from 'react-router-dom';

const Election = () => {
  const [poolData, setPoolDdata] = useState('')

  const SepcData2 =()=>{
      axios
      .get(
      `https://cms.iansnews.in/api/misc/?tags=national` 
      ).then((res) => {
      
          if(res.data.results &&
          res.data.results.length > 0 && res.data.results[0].description){
              setPoolDdata(res.data.results[0].description);
          } 
      });
  } 
  const [electBtn, setElectBtn] = useState(true)
  const EleCloseBtn =()=>{
      setElectBtn(!electBtn)
  }
  const MINUTE_MS = 30000;

  useEffect(() => {
    SepcData2()
   
    const interval = setInterval(() => {
       
        SepcData2()
      }, MINUTE_MS);
    
      return () => clearInterval(interval)
 
  }, [])


  return (
    <>
    {electBtn &&  
        <section>

            <div className="container">
                <div className="row">
                <button type="button" className="btn-close election-close-btn" aria-label="Close" onClick={EleCloseBtn}></button>
                <div className="points_table" dangerouslySetInnerHTML={{ __html:  poolData ? poolData : '' }} ></div>
          </div>
        </div>
      </section>
    
    }
    
    {   !electBtn && 
    <p className="show-results" onClick={EleCloseBtn}> <i className="bi bi-chevron-double-down"></i> SHOW ASSEMBLY ELECTION RESULTS <i className="bi bi-chevron-double-down"></i> </p>
    
    } 
    </>

  )
}

export default Election
